<template>
  <mobile-screen :header="true" screen-class="gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="about-header icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("global", "language", "language") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <section class="languages" v-if="sortedLanguages && sortedLanguages.length">
      <ul class="clebex-item-section pill">
        <li
          class="clebex-item-section-item full-right-underline"
          v-for="(language, index) in sortedLanguages"
          :key="index"
        >
          <button
            class="clebex-item-content-wrapper"
            @click="selectTranslationLanguage(language.locale)"
          >
            <span class="label">{{ language.name }}</span>
            <span class="follow-up-icons">
              <span
                class="follow-up-icon-item"
                v-if="selectedLanguage && selectedLanguage.id === language.id"
              >
                <icon icon="#cx-app1-checkmark"></icon>
              </span>
            </span>
          </button>
        </li>
      </ul>
    </section>
  </mobile-screen>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState } from "vuex";
import languagesMixin from "@/services/mixins/languages/languages-mixin";

export default {
  name: "SelectLanguage",
  computed: {
    ...mapState("language", ["languages"]),
    ...mapState("companyDocuments", ["selectedLanguage"]),
    sortedLanguages() {
      const { languages } = this;
      if (languages && languages.data && languages.data.length) {
        const sortedLanguages = languages.data.sort(this.sortLanguages);
        return sortedLanguages;
      }
      return null;
    }
  },
  methods: {
    /**
     * Set selected language locale to store and navigate back
     * @param {string} locale
     */
    selectTranslationLanguage(locale) {
      this.$store.commit("companyDocuments/setSelectedLanguage", locale, {
        root: true
      });
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    componentTitle: {
      type: String,
      required: true
    },
    componentLabel: {
      type: String,
      required: true
    }
  },
  mixins: [languagesMixin]
};
</script>
